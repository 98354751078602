* {
    box-sizing: border-box;
    scroll-behavior: smooth;
}

html {
    height: 100%;
    box-sizing: border-box;
    font-family: 'Oswald', sans-serif;
}

body {
    background-color: rgb(229, 201, 241, .25);
}

div,
.row {
    width: 100%;
    margin: 0;
}

p {
    font-family: 'Oswald', sans-serif;
}

a,
a:hover {
    text-decoration: none;
}

#headerSection p {
    color: rgb(245, 233, 233);
}

#aboutSection p:first-child {
    font-size: 1.8em; 
}

#portfolioSection p {
    font-size: 1.8em;
}

#hireMe p {
    font-size: 1.4em;
    font-weight: 300;
}

#skillSection div li {
    font-size: 1.2rem;
    font-weight: 200;
}

#skillSection div {
    font-size: 1.2rem;
}

#upperFooterSection p {
    font-size: 1.4em;
}

#lowerFooterSection p {
    font-size: 1.1em;
}

/*Header Styling*/
.outer-layer-container {
    display: flex;
    overflow: hidden
}

.main-header-container {
    background-image: url(./images/hero-bkg2.png);
    background-size: cover, cover;
    background-position: center;
    min-width: 100%;
    min-height: 80vh;
}

.subheader-container {
    background-color: rgb(136, 61, 108, .80);
    border-radius: 5px 5px;
    max-width: 500px;
}

.hero-content-section {
    align-content: left;

}

.hero-content-section h1 {
    text-align: left;
}

.hero-content-section p {
    color: #3B1858;
}


.chevronIcon {
    animation: hoverIt 3s infinite;
    color: rgb(236, 207, 207);
}

@keyframes hoverIt {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(.5em);
    }

    100% {
        transform: translateY(0px);
    }
}

/* Navbar Styling*/
.brand-logo {
    max-width: 2.5em;
    margin-right: 1em;
}

.nav-link {
    min-width: 6em;
}

/*Project Styling*/
.card-content-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.project-button {
    width: 8em;
    height: 2.5em;
    background-color: rgb(59, 61, 61);
    color: #fff;
    border-radius: 5px 5px;
    text-decoration: none;
    font-weight: 400;
    padding: .5em;
    text-align: center;
}

.project-button:hover {
    background-color: rgb(99, 98, 100);
    color: #fff;
}


.resume-button {
    width: 15em;
    height: 2.5em;
    background-color: #d7684a;
    color: #fff;
    border-radius: 5px 5px;
    text-decoration: none;
    font-weight: 400;
    padding: .5em;
    text-align: center;
}

.resume-button:hover {
    width: 15em;
    height: 2.5em;
    background-color: #e28a71;
    color: #fff;
    border-radius: 5px 5px;
    text-decoration: none;
    font-weight: 400;
    padding: .5em;
    text-align: center;
}

/*Skill Styling*/
.skill-card,
.project-card {
    box-shadow: 10px 10px 10px 4px rgb(150, 145, 145, .5);
    border: none;
}

.card-header {
    background-color: rgb(53, 17, 87);
    color: #fff;
}

.accordion-body > li {
    list-style: none;
}

.accordion-button {
    background-color: rgb(53, 17, 87);
    color: #fff;  
}

/* Changes chevron color in accordion button */
.accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}


/*Footer Styling*/
.social-icons {
    font-size: 1.6em;
    cursor: pointer;
    padding: 0;
    margin: 1em 1em;
}

.social-icon-list>li>a {
    color: rgb(236, 207, 207);
}

.upper-footer-container {
    background:
        linear-gradient(180deg, rgb(59, 157, 218), rgb(51, 21, 134));
    color: rgb(245, 233, 233);
}

.lower-footer-container {
    background:
        linear-gradient(180deg, rgb(51, 21, 134), rgb(53, 17, 87));
    color: rgb(245, 233, 233);

}

.lower-footer-container>div>p {
    margin: 0.5em;
}

/* @media (max-width: 400px) {
    .subheader-container {
        background-color: rgb(117, 40, 88, .80);
        border-radius: 5px 5px;


    }
} */